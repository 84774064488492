import { createContext, useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { DateValueType } from "react-tailwindcss-datepicker";

import ApplicationLayout from "layouts/application";
import SignIn from "views/auth/SignIn";
import { useProfile } from "hooks/useProfile";
import { AxiosError } from "axios";
import Wait from "views/auth/Wait";
import {
  getLastFourFullWeeks,
  getStartAndEndDateOfTheWeek,
} from "utils/dateUtils";

const ApplicationContext = createContext<any>(null);

export const useApplicationContext = () => useContext(ApplicationContext);

const App = () => {
  const { error, profile, authLevel, refetch: refetchProfile } = useProfile();
  const [searchString, setSearchString] = useState("");
  const [darkmode, setDarkmode] = useState(true);
  const [selectedDateForReport, setSelectedDateForReport] =
    useState<DateValueType>(getStartAndEndDateOfTheWeek(new Date()));
  const [selectedDateForCountries, setSelectedDateForCountries] =
    useState<DateValueType>(getLastFourFullWeeks(new Date()));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (error) {
      if ((error as AxiosError).response.status === 403) navigate("/signIn");
    }
  }, [error]);

  useEffect(() => {
    document.body.classList.add("dark");
  }, []);

  useEffect(() => {
    if (authLevel !== undefined) {
      if (authLevel === 0) navigate("/wait");
      if (authLevel === 1) navigate("/profile");
      if (authLevel === 2 && location.pathname === "/wait") navigate("/");
    }
  }, [authLevel, location.pathname]);

  return (
    <ApplicationContext.Provider
      value={{
        profile,
        refetchProfile,
        selectedDateForReport,
        setSelectedDateForReport,
        searchString,
        setSearchString,
        setSelectedDateForCountries,
        selectedDateForCountries,
        darkmode,
        setDarkmode,
      }}
    >
      <Routes>
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/wait" element={<Wait />} />
        <Route path="*" element={<ApplicationLayout />} />
      </Routes>
    </ApplicationContext.Provider>
  );
};

export default App;

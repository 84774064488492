import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import profileService from "../services/profile";

export const useProfile = () => {
  const {
    data: profile,
    isLoading,
    refetch,
    isError,
    error,
  } = useQuery({
    queryKey: ["profile"],
    queryFn: () => profileService.getProfile(),
    retry: (_failureCount, error) =>
      (error as AxiosError)?.response?.status !== 403,
  });

  const authLevel =
    !isLoading && profile
      ? !profile.is_allowed
        ? 0
        : !profile.nickname
        ? 1
        : 2
      : undefined;

  return { profile, isLoading, authLevel, refetch, isError, error };
};

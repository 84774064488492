import axios from "axios";
import { shortFormat, shortFormat1 } from "../utils/dateUtils";

export interface WeeklyReport {
  calendar_owner: string;
  owner_type: string;
  sunday_count: number;
  monday_count: number;
  tuesday_count: number;
  wednesday_count: number;
  thursday_count: number;
  friday_count: number;
  tech_count: number;
  final_count: number;
  saturday_count: number;
  total_count: number;
  total_interview_hours: number;
}

export interface FailedEvent {
  event_id: string;
  email: string;
  failed_at: string;
  summary: string;
}
export interface FailerReport {
  nickname: string;
  failerCount: number;
  failed_events: FailedEvent[];
}
export interface CountriesReport {
  [key: string]: number;
}

export interface CountriesPerUserReport {
  [key: string]: CountriesReport;
}

const service = {
  getWeekly: (date: Date) =>
    axios
      .get<WeeklyReport[]>(
        `/api/statistics/weekly-report?at=${shortFormat1(date)}`
      )
      .then((response) => response.data),
  getFailerReport: (date: Date) =>
    axios
      .get<FailerReport[]>(`/api/failed?at=${shortFormat1(date)}`)
      .then((response) => response.data),
  getCountriesReport: (date: Date) =>
    axios
      .get<CountriesReport>(
        `/api/statistics/countries?at=${shortFormat1(date)}`
      )
      .then((response) => response.data),
  getCountriesPerUserReport: (date: Date) =>
    axios
      .get<CountriesPerUserReport>(
        `/api/statistics/countries/per-user?at=${shortFormat1(date)}`
      )
      .then((response) => response.data),
  getPassRatePerUserReport: (startedAt: Date, endedAt: Date) =>
    axios
      .get<any>(
        `/api/statistics/passrate-report?startedAt=${shortFormat1(
          startedAt
        )}&endedAt=${shortFormat1(endedAt)}`
      )
      .then((response) => response.data),
};

export default service;

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { FiAlignJustify } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

import { useApplicationContext } from "App";
import { getStartAndEndDateOfTheWeek } from "../../utils/dateUtils";
import Dropdown from "components/dropdown";
import avatar from "assets/img/avatars/profile.png";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText } = props;
  const {
    profile,
    selectedDateForReport,
    setSelectedDateForReport,
    setSearchString,
    selectedDateForCountries,
    setSelectedDateForCountries,
    darkmode,
    setDarkmode,
  } = useApplicationContext();
  const [searchInput, setSearchInput] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#252f404d]">
      <div className="ml-[6px]">
        <div className={`h-6 w-[224px] pt-1`}>
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 md:flex-grow-0 md:gap-2 xl:gap-4 dark:!bg-slate-800 dark:shadow-none">
        <div
          className={`flex items-center ${
            location.pathname === "/statistics"
              ? "xl:w-[300px]"
              : "xl:w-[225px]"
          } ${
            location.pathname !== "/" &&
            "h-full rounded-full bg-lightPrimary text-navy-700 dark:bg-slate-800 dark:text-white"
          }`}
        >
          {location.pathname === "/" ? (
            <Datepicker
              key={"dashboardDatePicker"}
              useRange={false}
              asSingle={true}
              startWeekOn={"mon"}
              onChange={(date) => {
                const value = getStartAndEndDateOfTheWeek(
                  date?.startDate
                    ? typeof date?.startDate === "string"
                      ? new Date(date.startDate)
                      : date?.startDate
                    : new Date()
                );
                setSelectedDateForReport(value);
              }}
              value={selectedDateForReport}
            />
          ) : location.pathname === "/statistics" ? (
            <Datepicker
              key={"statisticsDatePicker"}
              startWeekOn={"mon"}
              onChange={(date) => {
                setSelectedDateForCountries({
                  startDate: new Date(date.startDate),
                  endDate: new Date(date.endDate),
                });
              }}
              value={selectedDateForCountries}
            />
          ) : (
            <>
              <p className="pl-3 pr-2 text-xl">
                <FiSearch
                  className={`h-4 w-4 text-gray-400 dark:text-white ${
                    location.pathname === "/" && "opacity-25"
                  }`}
                />
              </p>
              <input
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter") {
                    setSearchString(searchInput);
                    if (location.pathname !== "/questions") navigate("/events");
                  }
                }}
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 disabled:opacity-25 sm:w-fit dark:bg-slate-800 dark:text-white dark:placeholder:!text-white"
              />
            </>
          )}
        </div>

        <span
          className="flex cursor-pointer text-xl text-gray-600 xl:hidden dark:text-white"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={avatar}
              alt="User avatar"
            />
          }
          children={
            <div
              className={`flex ${
                profile?.user?.is_staff ? "h-40" : "h-32"
              } w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none`}
            >
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey,{" "}
                    {profile
                      ? profile.nickname !== ""
                        ? profile.nickname
                        : profile.user.email
                      : ""}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div
                className={`ml-4 ${
                  profile?.user?.is_staff ? "mt-3" : "mt-1"
                } flex flex-col`}
              >
                {profile?.user?.is_staff && (
                  <a
                    href="/admin/management"
                    target="_blank"
                    className="cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    Admin
                  </a>
                )}
                <div
                  onClick={() => navigate("/profile")}
                  className="mt-3 cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </div>
                <a
                  href="/signIn"
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;

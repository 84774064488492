import { useState, useMemo, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Creatable from "react-select/creatable";
import countryList from "react-select-country-list";

import { useApplicationContext } from "App";
import profileService, { Calendar } from "../../../services/profile";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { Select } from "./Select";
import { Button } from "./Button";

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface UICalendar {
  email: string;
  nation: string;
}

function EmailManagementCard() {
  const { profile, refetchProfile } = useApplicationContext();

  const commonCountries = [
    "US",
    "CA",
    "DE",
    "UK",
    "BE",
    "SE",
    "DK",
    "FR",
    "NL",
    "CH",
    "PL",
    "FI",
    "AT",
    "CO",
    "TW",
    "JP",
    "SG",
    "VN",
    "HK",
    "UA",
    "BR",
    "IE",
  ];

  const isInCommonCountries = (nation: string) =>
    commonCountries.includes(nation)
      ? commonCountries.length - commonCountries.indexOf(nation)
      : -1;

  const options = useMemo(
    () =>
      countryList()
        .getData()
        .sort(
          (a, b) => isInCommonCountries(b.value) - isInCommonCountries(a.value)
        ),
    []
  );

  const {
    data,
    isLoading,
    refetch: refetchCalendars,
  } = useQuery({
    queryKey: ["userEmails"],
    queryFn: () => profileService.getEmails(),
  });

  const [tempCalendars, setTempCalendars] = useState<Calendar[]>([]);

  useEffect(() => {
    if (data) setTempCalendars(data);
  }, [data]);

  const setCalendars = useMutation({
    mutationFn: () => profileService.setCalendars(tempCalendars),
  });

  if (!profile) return <></>;
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800 mb-5"}>
      <header className="relative flex flex-col py-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Email management
        </div>
        <div className="mt-8">
          <Creatable
            value={tempCalendars.map((calendar) => {
              return { label: calendar.email, value: calendar.id };
            })}
            isValidNewOption={(inputValue) =>
              String(inputValue).toLowerCase().match(emailRegex) ? true : false
            }
            isMulti
            onChange={(newCalendars) => {
              setTempCalendars(
                newCalendars.map((newCalendar) => {
                  const existCalendar = tempCalendars.filter(
                    (existingCalendar) =>
                      existingCalendar.email === newCalendar.label
                  );
                  if (existCalendar.length > 0) return { ...existCalendar[0] };
                  else {
                    return {
                      id: newCalendar.value,
                      email: newCalendar.label,
                      nation: "US",
                    };
                  }
                })
              );
            }}
            formatCreateLabel={(userInput) => `Add '${userInput}'`}
            placeholder=""
            noOptionsMessage={() => "Please input valid email address."}
            className="my-react-select-container w-full ml-3 pr-3"
            classNamePrefix="my-react-select"
          />
          <div className="mb-4 ml-3 mt-4 border border-t-0 dark:border-white dark:opacity-30  border-gray-300" />
          <div className="flex flex-col gap-2">
            {tempCalendars.map((calendar, index) => (
              <div className="flex flex-row gap-2" key={index}>
                <InputField
                  id={calendar.id.toString()}
                  label=""
                  extra="flex flex-row items-center w-96"
                  value={calendar.email}
                  placeholder=""
                  disabled
                  variant=""
                />
                <Select
                  options={options}
                  value={calendar.nation}
                  setValue={(v) => {
                    let newTempCalendars = [...tempCalendars];

                    newTempCalendars[index].nation = v;
                    setTempCalendars(newTempCalendars);
                  }}
                />
              </div>
            ))}
            <div className="w-52 py-2 px-3">
              <Button
                text="Save"
                disabled={
                  tempCalendars.filter((calendar) => calendar.nation === "")
                    .length > 0
                }
                onClick={async () => {
                  await setCalendars.mutateAsync();
                  refetchCalendars();
                }}
                isLoading={setCalendars.isPending}
              ></Button>
            </div>
          </div>
        </div>
      </header>
    </Card>
  );
}

export default EmailManagementCard;

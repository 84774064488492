import { useState } from "react";
import { FaHandsClapping } from "react-icons/fa6";
import DashBoardTable from "./components/DashboardTable";
import FailerTable from "./components/FailerTable";
import Card from "components/card";

const DashboardPage = () => {
  const [topPerformers, setTopPerformers] = useState([]);
  return (
    <div>
      <div className="mt-5 flex h-full grid-cols-1 flex-row gap-5">
        <div style={{ width: "80%" }}>
          <DashBoardTable setTopPerformers={setTopPerformers} />
        </div>
        <div
          className="gap-5"
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            extra={
              "w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800 h-fit py-3"
            }
            style={{ height: "fit-content" }}
          >
            <header className="relative flex items-center justify-between pb-4">
              <div className="flex flex-row items-center pt-1 text-xl font-bold text-navy-700 dark:text-white">
                Non Intro Calls <FaHandsClapping className="ml-2" />
              </div>
            </header>
            <div className="flex flex-col gap-1 text-sm">
              <table className="w-full">
                <thead>
                  <tr className="!border-px !border-gray-400">
                    <th
                      colSpan={1}
                      className="cursor-pointer border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start"
                    >
                      <div className="flex items-center justify-between text-xs text-gray-200">
                        <p className="whitespace-nowrap text-sm text-xs font-bold uppercase text-gray-600 dark:text-white">
                          Nickname
                        </p>
                      </div>
                    </th>
                    <th
                      colSpan={1}
                      className="cursor-pointer border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start"
                    >
                      <div className="flex items-center justify-between text-xs text-gray-200">
                        <p className="whitespace-nowrap text-sm text-xs font-bold uppercase text-gray-600 dark:text-white">
                          Count
                        </p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topPerformers.map((item) => {
                    return (
                      <tr className="cursor-pointer hover:opacity-70">
                        <td className="border-white/0 py-2  pr-4">
                          <p className="text-sm text-navy-700 dark:text-white">
                            {item.calendar_owner}
                          </p>
                        </td>
                        <td className="border-white/0 py-2  pr-4">
                          <p className="text-sm text-navy-700 dark:text-white">
                            {item.tech_count + item.final_count}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card>
          <FailerTable />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

import {
  MdBarChart,
  MdTableRows,
  MdAccountCircle,
  MdQuestionMark,
} from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import StatisticsPage from "views/Statistics";
import DashboardPage from "views/Dashboard";
import EventsPage from "views/Events";
import ProfilePage from "views/Profile";
import QuestionsPage from "views/Questions";

const routes = [
  {
    name: "Dashboard",
    icon: <RiDashboardFill className="h-6 w-6" />,
    path: "/",
    component: <DashboardPage />,
  },
  {
    name: "Events",
    icon: <MdTableRows className="h-6 w-6" />,
    path: "/events",
    component: <EventsPage />,
  },
  {
    name: "Questions",
    icon: <MdQuestionMark className="h-6 w-6" />,
    path: "/questions",
    component: <QuestionsPage />,
  },
  {
    name: "Statistics",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/statistics",
    component: <StatisticsPage />,
  },
  {
    name: "My profile",
    icon: <MdAccountCircle className="h-6 w-6" />,
    path: "/profile",
    component: <ProfilePage />,
  },
];

export default routes;

import QuestionsTable from "./components/QuestionsTable";


const QuestionsPage = () => {
  return (
    <div>
      <div className="mt-5 flex h-full grid-cols-1 flex-row gap-5">
          <QuestionsTable />
      </div>
    </div>
  );
};

export default QuestionsPage;

import { useEffect, useState, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { TbSortDescending, TbSortAscending } from "react-icons/tb";
import { Hourglass } from "react-loader-spinner";
import { FaCalendar } from "react-icons/fa";
import Drawer from "react-modern-drawer";

import statisticService, {
  FailedEvent,
  FailerReport,
} from "../../../services/statistic";
import calendarService from "services/calendar";
import Card from "components/card";
import { useApplicationContext } from "App";
import { Button } from "../../Profile/components/Button";

const tableHeads: {
  value: "nickname" | "failerCount";
  label: string;
}[] = [
  { value: "nickname", label: "Nickname" },
  { value: "failerCount", label: "Count" },
];

type RowObj = {
  nickname: string;
  failerCount: number;
  failed_events: FailedEvent[];
};

function FailerTable() {
  const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);
  const [selectedFailedEvent, setSelectedFailedEvent] =
    useState<FailerReport>();
  const [sorting, setSorting] = useState<SortingState>([
    { id: "failerCount", desc: true },
  ]);
  const { selectedDateForReport } = useApplicationContext();
  const weekDate = useMemo(
    () => selectedDateForReport,
    [selectedDateForReport.startDate]
  );
  const queryClient = useQueryClient();

  const columns = tableHeads.map((head) =>
    columnHelper.accessor(head.value, {
      id: head.value,
      header: () => (
        <p
          className="whitespace-nowrap text-sm font-bold uppercase text-gray-600 dark:text-white"
          style={{ fontSize: "12px" }}
        >
          {head.label}
        </p>
      ),
      cell: (info) => {
        return (
          <p className="text-sm text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        );
      },
    })
  );

  const { data, isLoading } = useQuery({
    queryKey: ["failerReports", weekDate.startDate.toISOString()],
    queryFn: () =>
      statisticService.getFailerReport(weekDate?.startDate as Date),
    refetchInterval: 300000,
  });

  const { mutate: refreshFailedEvents, isPending } = useMutation({
    mutationFn: (eventIds: string[]) =>
      calendarService.refreshFailedEvents(eventIds),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["weeklyReports"],
      });
      queryClient.invalidateQueries({
        queryKey: ["failerReports"],
      });
      setIsDetailDrawerOpen(false);
    },
    onError: () => {
      alert("Something went wrong. Please reach out to Admin.");
    },
  });

  const eventData = useMemo(() => {
    const tempData = data ? [...data] : [];
    tempData.sort((a, b) => b.failerCount - a.failerCount);
    return tempData;
  }, [data]);

  const table = useReactTable({
    data: eventData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const summaryByEmailForFailedEvents = (
    selectedFailedEvent?.failed_events || []
  ).reduce((accumulator, currentItem) => {
    // Find an existing object in the accumulator array with the same email
    const existingItem = accumulator.find(
      (item) => item.email === currentItem.email
    );

    if (existingItem) {
      existingItem.summary.push(currentItem.summary);
    } else {
      accumulator.push({
        email: currentItem.email,
        eventId: currentItem.event_id,
        summary: [currentItem.summary],
      });
    }

    return accumulator;
  }, []);

  return (
    <>
      <Card
        extra={
          "w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800 h-min pb-1"
        }
      >
        <header className="relative flex items-center justify-between py-4">
          <div className="flex flex-row items-center pt-1 text-xl font-bold text-navy-700 dark:text-white">
            Unrecorded Events
            <FaCalendar className="ml-2" />
          </div>
        </header>

        {
          <div className="overflow-x-auto xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className="cursor-pointer border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start"
                        >
                          <div className="flex items-center justify-between text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {eventData.length > 0 &&
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.original.nickname + "unrecorded_events"}
                        className="cursor-pointer hover:opacity-70"
                        onClick={() => {
                          setSelectedFailedEvent(row.original);
                          setIsDetailDrawerOpen((flag) => !flag);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="border-white/0 py-2  pr-4"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {(eventData.length === 0 || isLoading) && (
              <div className="mb-5 flex w-full justify-center pt-2">
                {isLoading ? (
                  <div className="m-auto flex flex-row items-center justify-center gap-3">
                    <Hourglass
                      visible={true}
                      height="15"
                      width="15"
                      ariaLabel="hourglass-loading"
                      colors={["#306cce", "#72a1ed"]}
                    />{" "}
                    Loading...
                  </div>
                ) : (
                  <>No data found.</>
                )}
              </div>
            )}
          </div>
        }
      </Card>
      <Drawer
        open={isDetailDrawerOpen}
        direction="right"
        className="flex !w-[500px] flex-col gap-5 overflow-y-auto bg-lightPrimary px-10 py-10 dark:!bg-slate-800 dark:text-white"
        onClose={() => setIsDetailDrawerOpen((flag) => !flag)}
        duration={150}
      >
        <div className="flex flex-col gap-5">
          <b className="text-xl">
            <u>Unrecorded events for {selectedFailedEvent?.nickname}</u>
          </b>

          {summaryByEmailForFailedEvents &&
            summaryByEmailForFailedEvents.map((event) => (
              <div className="flex flex-col gap-2" key={event.event_id}>
                <div>
                  <b>{event.email}</b>
                </div>
                <div className="pl-4">
                  {event.summary.map((summary: string, index: number) => (
                    <div key={"failed_summary_" + index + event.event_id}>
                      {summary}
                    </div>
                  ))}
                </div>
              </div>
            ))}

          <div className="flex flex-row-reverse">
            <Button
              text={isPending ? "Refreshing..." : "Refresh These Events"}
              isLoading={isPending}
              disabled={isPending}
              onClick={() =>
                refreshFailedEvents(
                  (summaryByEmailForFailedEvents || []).map(
                    (event) => event.eventId
                  )
                )
              }
            ></Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default FailerTable;
const columnHelper = createColumnHelper<RowObj>();

import { DateRangeType, DateType } from "react-tailwindcss-datepicker";

const dateOptions: Intl.DateTimeFormatOptions = {
  timeZone: "Asia/Tokyo",
  hourCycle: "h23",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
};

export const toDate = (dateString: string | undefined): Date =>
  !dateString ? new Date() : new Date(dateString);

export const shortFormat = (date: Date) =>
  date.toLocaleString("en-US", dateOptions);

export const shortFormat1 = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getStartAndEndDateOfTheWeek = (
  selectedDate: Date
): DateRangeType => {
  const currentDayOfWeek = selectedDate.getDay();
  const daysUntilMonday = (currentDayOfWeek + 6) % 7;
  const mondayDate = new Date(selectedDate);
  mondayDate.setDate(selectedDate.getDate() - daysUntilMonday);
  const sundayDate = new Date(mondayDate);
  sundayDate.setDate(mondayDate.getDate() + 6);

  return { startDate: mondayDate, endDate: sundayDate };
};

export const getLastFourFullWeeks = (selectedDate: Date): DateRangeType => {
  // Find the previous Sunday from the selected date
  const previousSunday = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate() - selectedDate.getDay()
  );

  // Calculate the start date as four weeks (28 days) before the previous Sunday
  const startDate = new Date(
    previousSunday.getFullYear(),
    previousSunday.getMonth(),
    previousSunday.getDate() - 28
  );

  // The end date is the Saturday just before the previous Sunday
  const endDate = new Date(
    previousSunday.getFullYear(),
    previousSunday.getMonth(),
    previousSunday.getDate() - 1
  );

  return { startDate, endDate };
};

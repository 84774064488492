import { useState, useMemo, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  useInfiniteQuery,
} from "@tanstack/react-query";
import { Hourglass } from "react-loader-spinner";
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

import Card from "components/card";
import { useApplicationContext } from "App";
import calendarService, {
  Questions,
} from "services/calendar";
import { Button } from "views/Profile/components/Button";

const tableHeads: {
  value:
    | "question"
    | "encounters";
  label: string;
}[] = [
  { value: "encounters", label: "Encounters" },
  { value: "question", label: "Question" },
];

const similarQuestionsTableHeads = ["QUESTION", "USER", "COMPANY"];

type RowObj = Questions;

function QuestionsTable() {
  const { searchString } = useApplicationContext();
  const [selectedQuestion, setSelectedQuestion] = useState<Questions>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const {
    data: similarQuestions,
    isFetching: isSimilarQuestionsLoading,
    hasNextPage: hasMoreSimilarQuestions,
    fetchNextPage: fetchMoreSimilarQuestions,
  } = useInfiniteQuery({
    queryKey: ["similarQuestions", selectedQuestion],
    queryFn: ({ pageParam }) =>
      calendarService.getSimilarQuestions(
        selectedQuestion.id,
        pageParam as number,
        15,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    getPreviousPageParam: (firstPage) => firstPage.previous,
  });

  const columns = tableHeads.map((head) =>
    columnHelper.accessor(head.value, {
      id: head.value,
      header: () => (
        <p
          className="whitespace-nowrap font-bold uppercase text-gray-600 dark:text-white"
          style={{ fontSize: "12px" }}
        >
          {head.label}
        </p>
      ),
      cell: (info) => {
          return (
            <p className="text-sm text-navy-700 dark:text-white">
              {info.getValue()}
            </p>
          );
      },
    })
  );

  const {
    data,
    isFetching: isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["calendars", searchString],
    queryFn: ({ pageParam }) =>
      calendarService.getQuestions(
        15,
        pageParam as number,
        searchString
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    getPreviousPageParam: (firstPage) => firstPage.previous,
  });

  const eventData = useMemo(() => {
    return (
      data && Array.isArray(data.pages.flatMap((page) => page.results))
        ? data.pages.flatMap((page) => page.results)
        : []
    );
  }, [data]);

  const similarQuestionsData = useMemo(() => {
    return (
      similarQuestions && Array.isArray(similarQuestions.pages.flatMap((page) => page.results))
        ? similarQuestions.pages.flatMap((page) => page.results)
        : []
    );
  }, [similarQuestions]);

  const table = useReactTable({
    data: eventData,
    columns,
    enableSortingRemoval: true,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const [loadMoreRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: "0px 0px 50px 0px",
  });

  useEffect(() => {
    if(isDrawerOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  },[isDrawerOpen])

  return (
    <>
      <Card extra={`w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800`}>
        <header className="relative flex items-center justify-between py-4">
          <div className="pt-1 text-xl font-bold text-navy-700 dark:text-white">
            Questions
          </div>
        </header>

        {
          <div className="overflow-x-auto xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                    data-drawer-target="drawer-right-example"
                    data-drawer-show="drawer-right-example"
                    data-drawer-placement="right"
                    aria-controls="drawer-right-example"
                  >
                    {headerGroup.headers.map((header, index) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start ${index === 0 && 'w-[120px]'}`}
                        >
                          <div className="flex items-center justify-between gap-2 text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {data &&
                  eventData.length > 0 &&
                  table.getRowModel().rows.map((row, ind) => {
                    return (
                      <tr
                        key={row.id}
                        className="cursor-pointer hover:opacity-70"
                        onClick={() => {
                          setSelectedQuestion(eventData[ind]);
                          setIsDrawerOpen(true);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="border-white/0 py-3  pr-4"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {(eventData.length === 0 || isLoading) && (
              <div className="mb-5 flex w-full justify-center pt-2">
                {isLoading ? (
                  <div className="m-auto flex flex-row items-center justify-center gap-3">
                    <Hourglass
                      visible={true}
                      height="15"
                      width="15"
                      ariaLabel="hourglass-loading"
                      colors={["#306cce", "#72a1ed"]}
                    />{" "}
                    Loading...
                  </div>
                ) : (
                  <>No data found.</>
                )}
              </div>
            )}
            {hasNextPage && <div ref={loadMoreRef}></div>}
          </div>
        }
      </Card>
      <Drawer
        open={isDrawerOpen}
        direction="right"
        className="flex !w-[900px] flex-col gap-5 overflow-y-auto bg-lightPrimary px-10 py-10 dark:!bg-slate-800  dark:text-white"
        onClose={() => setIsDrawerOpen((flag) => !flag)}
        duration={150}
      >
        <div className="mb-5">
          <b>
              Similar questions to: {" "}
              <u>{selectedQuestion?.question}</u>
          </b>
        </div>
        <table>
          <thead>
            <tr
              className="!border-px !border-gray-400"
              data-drawer-target="drawer-right-example"
              data-drawer-show="drawer-right-example"
              data-drawer-placement="right"
              aria-controls="drawer-right-example"
            >
              {
                similarQuestionsTableHeads.map((tbhead, ind) => 
                  <th
                    className={`border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start ${ind === 0 && '!w-[300px]'}`}
                  >
                    <div className="flex items-center justify-between gap-2 text-xs text-gray-200">{tbhead}</div>
                  </th>
                )
              }
            </tr>
          </thead>
          <tbody>
            {similarQuestionsData.map(question => 
              <tr className="cursor-pointer hover:opacity-70 text-sm">
                <td className="border-white/0 py-3  pr-4 line-clamp-3 w-[400px]">{question.question}</td>
                <td className="border-white/0 py-3  pr-4">{question.nickname === "" ? question.email : question.nickname}</td>
                <td className="border-white/0 py-3  pr-4">{question.company}</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex flex-row-reverse">
          {
            hasMoreSimilarQuestions && 
              <Button
              text={isSimilarQuestionsLoading ? "Loading..." : "Load more"}
              isLoading={isSimilarQuestionsLoading}
              disabled={isSimilarQuestionsLoading}
              onClick={() => fetchMoreSimilarQuestions()}
            ></Button>
          }
        </div>
      </Drawer>
    </>
  );
}

export default QuestionsTable;
const columnHelper = createColumnHelper<RowObj>();

import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useApplicationContext } from "App";
import profileService from "../../../services/profile";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { Button } from "./Button";

function NickNameCard() {
  const { profile, refetchProfile } = useApplicationContext();
  const [nickNameValue, setNickNameValue] = useState(
    !profile ? "" : profile.nickname
  );

  const { refetch: refetchCalendars } = useQuery({
    queryKey: ["userEmails"],
    queryFn: () => profileService.getEmails(),
  });

  const saveProfile = useMutation({
    mutationFn: () =>
      profileService.setProfile(nickNameValue, profile.calendars),
  });

  if (!profile) return <></>;
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800"}>
      <header className="relative flex flex-col py-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          👋 Welcome,{" "}
          {profile
            ? profile.nickname !== ""
              ? profile.nickname
              : profile.user.email
            : ""}
        </div>
        <div className="mt-8 pl-1">
          <InputField
            id="primary_email"
            label="Primary email: "
            extra="flex flex-row items-center w-96 gap-2"
            value={profile.user.email}
            placeholder=""
            disabled
            variant=""
          />
          <div className="mb-4 ml-3 mt-4 border border-t-0 dark:border-white dark:opacity-30  border-gray-300" />
          <div className="mt-6 flex flex-row  gap-5">
            <InputField
              id="primary_email"
              label="Nickname: "
              extra="flex flex-row items-center w-96 gap-7"
              value={nickNameValue}
              setValue={setNickNameValue}
              placeholder="Please input your nickname."
              variant=""
              state={profile.nickname === "" ? "error" : ""}
            />
            <Button
              text="Save"
              onClick={async () => {
                await saveProfile.mutateAsync();
                refetchProfile();
                refetchCalendars();
              }}
              isLoading={saveProfile.isPending}
            ></Button>
          </div>
        </div>
      </header>
    </Card>
  );
}

export default NickNameCard;

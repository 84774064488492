import EventsTable from "./components/EventsTable";

const EventsPage = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <EventsTable />
      </div>
    </div>
  );
};

export default EventsPage;

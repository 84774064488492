import { FcGoogle } from "react-icons/fc";

export default function SignIn() {
  return (
    <div className="flex h-[100vh] w-full items-center justify-center dark:!bg-gray-800">
      {/* Sign in section */}
      <div className="mt-[10vh] flex w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-800 dark:text-gray-400">
          Welcome to the CallBook. Please Login.
        </p>
        <div
          className="mb-6 flex h-[50px] w-fit items-center justify-center gap-2 rounded-xl border border-gray-500 bg-lightPrimary px-5 hover:cursor-pointer dark:bg-navy-800"
          onClick={() => (window.location.href = "/auth/login/google-oauth2")}
        >
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";

interface Event {
  id: string;
  summary: string;
  description: string;
  organizer: {
    email: string;
    displayName: string;
  };
}

interface Calendar {
  id: string;
  events: Event[];
}

export interface Interviewer {
  name: string;
  position: string;
}

interface Question {
  question: string;
}

export interface Interview {
  id?: number;
  event_id: string;
  started_at: string;
  ended_at: string;
  company: string;
  keywords: string[];
  nickname: string;
  profile_country_code: string;
  profile_country_name: string;
  position: string;
  email: string;
  step: string;
  company_country_code: string;
  company_country_name: string;
  interviewers: Interviewer[];
  questions: Question[];
}

export interface InterviewResponse {
  count: number;
  next: any;
  previous: any;
  results: Interview[];
}

export interface Questions {
  id: number;
  question: string;
  encounters: number;
}

export interface QuestionResponse {
  count: number;
  next: any;
  previous: any;
  results: Questions[];
}

const service = {
  getCalendars: () =>
    axios.get<Calendar[]>("/api/calendars").then((response) => response.data),

  getInterviews: (
    pageSize: number,
    pageNum: number,
    orderBy: string,
    searchString: string
  ) =>
    axios
      .get<InterviewResponse>(
        `/api/interviews?${
          searchString !== "" ? `search=${searchString}` : ""
        }&order_by=${orderBy}&page=${pageNum}&page_size=${pageSize}`
      )
      .then((response) => response.data),

  getQuestions: (
        pageSize: number,
        pageNum: number,
        searchString: string
      ) =>
        axios
          .get<QuestionResponse>(
            `/api/questions?${
              searchString !== "" ? `q=${searchString}` : ""
            }&page=${pageNum}&page_size=${pageSize}`
          )
          .then((response) => response.data),

  getSimilarQuestions: (id: number, pageNum: number, pageSize: number) =>
            id > 0 && axios.get(`/api/similar-questions/${id}?page=${pageNum}&page_size=${pageSize}`).then((response) => response.data),

  refreshInterview: (id: number) =>
    axios
      .post<Interview>(`/api/interviews/${id}/refresh`)
      .then((response) => response.data),

  refreshFailedEvents: (eventIds: string[]) =>
    axios
      .post<{result: boolean}>(`/api/failed/refresh`, { event_ids: eventIds })
      .then((response) => response.data),
};

export default service;

import { useState, useMemo, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { TbSortDescending, TbSortAscending } from "react-icons/tb";
import { Hourglass } from "react-loader-spinner";
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

import Card from "components/card";
import { useApplicationContext } from "App";
import calendarService, {
  Interview,
  InterviewResponse,
  Interviewer,
} from "services/calendar";
import { shortFormat, toDate } from "utils/dateUtils";
import { Button } from "../../Profile/components/Button";
import { uniqueId } from "lodash";

const tableHeads: {
  value:
    | "nickname"
    | "company"
    | "company_country_code"
    | "profile_country_code"
    | "position"
    | "keywords"
    | "step"
    | "started_at"
    | "ended_at"
    | "interviewers";
  label: string;
}[] = [
  { value: "nickname", label: "Nickname" },
  { value: "company", label: "Company" },
  { value: "company_country_code", label: "Country" },
  { value: "profile_country_code", label: "Profile" },
  { value: "position", label: "Position" },
  { value: "keywords", label: "Keywords" },
  { value: "step", label: "Step" },
  { value: "started_at", label: "Started At" },
  { value: "ended_at", label: "Ended At" },
  { value: "interviewers", label: "Interviewers" },
];
const sortingEnabledColumns = [
  "started_at",
  "ended_at",
  "email",
  "position",
  "company",
];

type RowObj = Interview;

function EventsTable() {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "started_at", desc: true },
  ]);
  const { searchString } = useApplicationContext();
  const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState<Interview>();

  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) => calendarService.refreshInterview(id),
    onSuccess(interview, id) {
      const data = queryClient.getQueryData<InfiniteData<InterviewResponse>>([
        "calendars",
      ]);
      if (!data) {
        return;
      }

      data.pages.forEach((page) => {
        const idx = page.results.findIndex((item) => item.id === id);
        if (idx >= 0) {
          page.results.splice(idx, 1, interview);
        }
      });

      queryClient.setQueryData(["calendars"], { ...data });
      setSelectedInterview(interview);
    },
    onError: () => {
      alert("Something went wrong. Please reach out to Admin.");
    },
  });

  const columns = tableHeads.map((head) =>
    columnHelper.accessor(head.value, {
      id: head.value,
      header: () => (
        <p
          className="whitespace-nowrap font-bold uppercase text-gray-600 dark:text-white"
          style={{ fontSize: "12px" }}
        >
          {head.label}
        </p>
      ),
      enableSorting: sortingEnabledColumns.includes(head.value),
      cell: (info) => {
        if (head.value === "keywords") {
          return (
            <p className="text-sm text-navy-700 dark:text-white">
              {(info.getValue() as string[]).join(", ")}
            </p>
          );
        } else if (head.value === "started_at" || head.value === "ended_at") {
          return (
            <p className="text-sm text-navy-700 dark:text-white">
              {shortFormat(toDate(info.getValue() as string))}
            </p>
          );
        } else if (head.value === "interviewers") {
          return (
            <p className="text-sm text-navy-700 dark:text-white">
              {(info.getValue() as Interviewer[])
                .map(
                  (interviewer) =>
                    `${interviewer.name}${
                      interviewer.position && " (" + interviewer.position + ")"
                    }`
                )
                .join(", ")}
            </p>
          );
        } else if (head.value === "step") {
          return (
            <p className="text-sm text-navy-700 dark:text-white">
              {info.getValue() === "" ? "intro" : (info.getValue() as string)}
            </p>
          );
        } else {
          return (
            <p className="text-sm text-navy-700 dark:text-white">
              {info.getValue() as string}
            </p>
          );
        }
      },
    })
  );

  const {
    data,
    isFetching: isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["calendars", sorting, searchString],
    queryFn: ({ pageParam }) =>
      calendarService.getInterviews(
        15,
        pageParam as number,
        `${sorting[0].desc ? "-" : ""}${sorting[0].id}`,
        searchString
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    getPreviousPageParam: (firstPage) => firstPage.previous,
  });

  const eventData = useMemo(() => {
    return (
      data && Array.isArray(data.pages.flatMap((page) => page.results))
        ? data.pages.flatMap((page) => page.results)
        : []
    ).map((row) => {
      return {
        ...row,
        nickname: row.nickname !== "" ? row.nickname : row.email,
      };
    });
  }, [data]);

  const table = useReactTable({
    data: eventData,
    columns,
    state: {
      sorting,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const [loadMoreRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: "0px 0px 50px 0px",
  });

  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800"}>
        <header className="relative flex items-center justify-between py-4">
          <div className="pt-1 text-xl font-bold text-navy-700 dark:text-white">
            Interviews
          </div>
        </header>

        {
          <div className="overflow-x-auto xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                    data-drawer-target="drawer-right-example"
                    data-drawer-show="drawer-right-example"
                    data-drawer-placement="right"
                    aria-controls="drawer-right-example"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start"
                        >
                          <div className="flex items-center justify-between gap-2 text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <div className="text-black">
                              {(header.column.getIsSorted() as string) ===
                              "desc" ? (
                                <TbSortDescending className="text-gray-800 dark:text-white" />
                              ) : (header.column.getIsSorted() as string) ===
                                "asc" ? (
                                <TbSortAscending className="text-gray-800 dark:text-white" />
                              ) : (
                                <TbSortAscending className="opacity-0" />
                              )}
                            </div>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {data &&
                  eventData.length > 0 &&
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className="cursor-pointer hover:opacity-70"
                        onClick={() => {
                          setSelectedInterview(row.original);
                          setIsDetailDrawerOpen((flag) => !flag);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="border-white/0 py-3  pr-4"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {(eventData.length === 0 || isLoading) && (
              <div className="mb-5 flex w-full justify-center pt-2">
                {isLoading ? (
                  <div className="m-auto flex flex-row items-center justify-center gap-3">
                    <Hourglass
                      visible={true}
                      height="15"
                      width="15"
                      ariaLabel="hourglass-loading"
                      colors={["#306cce", "#72a1ed"]}
                    />{" "}
                    Loading...
                  </div>
                ) : (
                  <>No data found.</>
                )}
              </div>
            )}
            <div ref={loadMoreRef} className="h-1 w-full"></div>
          </div>
        }
      </Card>
      <Drawer
        open={isDetailDrawerOpen}
        direction="right"
        className="flex !w-[500px] flex-col gap-5 overflow-y-auto bg-lightPrimary px-10 py-10 dark:!bg-slate-800  dark:text-white"
        onClose={() => setIsDetailDrawerOpen((flag) => !flag)}
        duration={150}
      >
        <div className="mb-5">
          <b>
            <u>
              Interview detail for{" "}
              {selectedInterview?.nickname !== ""
                ? selectedInterview?.nickname
                : selectedInterview?.email}
            </u>
          </b>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col space-y-6 dark:text-white">
            <span
              className="text-base leading-relaxed"
              style={{ marginTop: 0 }}
            >
              <b>Profile:</b> {selectedInterview?.email} (
              {selectedInterview?.profile_country_name || "--"})
            </span>
            <span className="text-base leading-relaxed">
              <b>Company:</b> {selectedInterview?.company} (
              {selectedInterview?.company_country_name || "--"})
            </span>
            <span className="text-base leading-relaxed">
              <b>Position:</b> {selectedInterview?.position}
            </span>
            <span className="text-base leading-relaxed">
              <b>Keywords:</b> {selectedInterview?.keywords.join(", ")}
            </span>
            <span className="text-base leading-relaxed">
              <b>Interviewers:</b>
              <div className="flex flex-col gap-1">
                {selectedInterview?.interviewers.map((interviewer) => (
                  <span key={uniqueId()}>{`${interviewer.name.trim()}${
                    interviewer.position && " (" + interviewer.position + ")"
                  }`}</span>
                ))}
              </div>
            </span>
            <span className="text-base leading-relaxed">
              <b>Time:</b> {shortFormat(toDate(selectedInterview?.started_at))}{" "}
              ~ {shortFormat(toDate(selectedInterview?.ended_at))}
            </span>
            <span className="text-base leading-relaxed">
              <b>Step:</b> {selectedInterview?.step}
            </span>
          </div>
          <div>
            <div>
              <b>Questions</b>
            </div>
            <div className="flex flex-col flex-wrap text-base leading-relaxed">
              {selectedInterview?.questions.map((question) => (
                <span key={uniqueId()}>{question.question}</span>
              ))}
            </div>
          </div>
          <div className="flex flex-row-reverse">
            <Button
              text={isPending ? "Refreshing..." : "Refresh"}
              isLoading={isPending}
              disabled={isPending}
              onClick={() => mutate(selectedInterview?.id || 0)}
            ></Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default EventsTable;
const columnHelper = createColumnHelper<RowObj>();

import React, { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { TbSortDescending, TbSortAscending } from "react-icons/tb";
import { Hourglass } from "react-loader-spinner";

import statisticService from "../../../services/statistic";
import Card from "components/card";
import { useApplicationContext } from "App";

type RowObj = {
  [key: string]: string;
};

const tableHeads: {
  value: string;
  label: string;
}[] = [
  { value: "calendar_owner", label: "Nickname" },
  { value: "pass_rate", label: "Passrate (%)" },
  { value: "total_count", label: "Total" },
];

const columnHelper = createColumnHelper<RowObj>();

function StatisticsTable() {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "pass_rate", desc: true },
  ]);
  const { selectedDateForCountries } = useApplicationContext();
  const weekDate = useMemo(
    () => selectedDateForCountries,
    [selectedDateForCountries]
  );

  const { data, isLoading } = useQuery({
    queryKey: [
      "PassRateReportsPerUser",
      `${weekDate.startDate.toISOString()} ${weekDate.endDate.toISOString()}`,
    ],
    queryFn: () =>
      statisticService.getPassRatePerUserReport(
        weekDate?.startDate as Date,
        weekDate?.endDate as Date
      ),
    refetchInterval: 300000,
  });

  const tableData = useMemo(
    () =>
      (data ?? []).map((item: any) => ({
        ...item,
        pass_rate:
          item.total_count - item.tech_count - item.final_count === 0
            ? 0
            : Math.round(
                (((item.tech_count + item.final_count) /
                  (item.total_count - item.tech_count - item.final_count)) *
                  100 +
                  Number.EPSILON) *
                  100
              ) / 100,
      })),
    [data]
  );
  const dataColNames: (keyof RowObj)[] = [
    "total_count",
    "final_count",
    "tech_count",
  ];

  const columns = tableHeads.map((head) =>
    columnHelper.accessor(head.value, {
      id: head.value,
      header: () => (
        <p
          className="whitespace-nowrap text-sm font-bold uppercase text-gray-600 dark:text-white"
          style={{ fontSize: "12px" }}
        >
          {head.label}
        </p>
      ),
      cell: (info: any) => {
        return (
          <p className="text-sm text-navy-700 dark:text-white">
            {info.column.id === "total_count"
              ? `${info.getValue()} (${
                  info.row.original.total_count -
                  info.row.original.tech_count -
                  info.row.original.final_count
                } / ${info.row.original.tech_count} / ${
                  info.row.original.final_count
                })`
              : info.column.id === "pass_rate"
              ? Math.round(info.getValue())
              : info.getValue()}
          </p>
        );
      },
    })
  );

  const sortedTableData = useMemo(() => {
    const tempTableData = [...tableData];
    tempTableData.sort((a: any, b: any) => {
      if (!sorting[0]) return 0;
      const res =
        sorting[0].id === "pass_rate"
          ? a.pass_rate === b.pass_rate
            ? a.pass_rate === 0
              ? b.total_count - a.total_count
              : a.total_count - b.total_count
            : a.pass_rate - b.pass_rate
          : sorting[0].id === "calendar_owner"
          ? a.calendar_owner.localeCompare(b.calendar_owner)
          : sorting[0].id === "total_count"
          ? a.total_count - b.total_count
          : 0;
      return !sorting[0].desc ? res : -1 * res;
    });
    return tempTableData;
  }, [sorting, tableData]);

  const tableData1 = useMemo(() => {
    const middleIndex = Math.ceil(sortedTableData.length / 2);
    return sortedTableData.slice(0, middleIndex);
  }, [sortedTableData]);

  const tableData2 = useMemo(() => {
    const middleIndex = Math.ceil(sortedTableData.length / 2);
    return sortedTableData.slice(middleIndex);
  }, [sortedTableData]);

  const table = useReactTable({
    data: tableData1,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const table1 = useReactTable({
    data: tableData2,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800"}>
      <header className="relative flex items-center justify-between py-4">
        <div className="pt-1 text-xl font-bold text-navy-700 dark:text-white">
          Statistics
        </div>
      </header>

      {
        <div className="flex flex-row justify-between overflow-x-auto xl:overflow-x-hidden">
          <table className="w-[48%]">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start"
                      >
                        <div className="flex items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <div>
                            {(header.column.getIsSorted() as string) ===
                            "desc" ? (
                              <TbSortDescending className="text-gray-800 dark:text-white" />
                            ) : (header.column.getIsSorted() as string) ===
                              "asc" ? (
                              <TbSortAscending className="text-gray-800 dark:text-white" />
                            ) : (
                              <TbSortAscending className="opacity-0" />
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {tableData.length > 0 &&
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.original.NICKNAME}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="border-white/0 py-3  pr-4">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="border-gray my-8 border-r opacity-20"></div>
          <table className="w-[48%]">
            <thead>
              {table1.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start"
                      >
                        <div className="flex items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <div>
                            {(header.column.getIsSorted() as string) ===
                            "desc" ? (
                              <TbSortDescending className="text-gray-800 dark:text-white" />
                            ) : (header.column.getIsSorted() as string) ===
                              "asc" ? (
                              <TbSortAscending className="text-gray-800 dark:text-white" />
                            ) : (
                              <TbSortAscending className="opacity-0" />
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {tableData.length > 0 &&
                table1.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.original.NICKNAME}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="border-white/0 py-3  pr-4">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {(tableData.length === 0 || isLoading) && (
            <div className="mb-5 flex w-full justify-center pt-2">
              {isLoading ? (
                <div className="m-auto flex flex-row items-center justify-center gap-3">
                  <Hourglass
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="hourglass-loading"
                    colors={["#306cce", "#72a1ed"]}
                  />{" "}
                  Loading...
                </div>
              ) : (
                <>No data found.</>
              )}
            </div>
          )}
        </div>
      }
    </Card>
  );
}

export default StatisticsTable;

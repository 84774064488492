export const Select = ({
  options,
  value,
  setValue,
}: {
  options: any[];
  value: string;
  setValue: (v: string) => void;
}) => {
  return (
    <select
      onChange={(e) => setValue(e.target.value)}
      id="countries"
      value={value}
      className={`block h-8 w-72 rounded-lg border border-gray-300 bg-gray-50 px-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-800 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 ${
        value === "" &&
        " !border-red-600 !text-red-500 focus:!border-red-600 dark:focus:!border-red-600"
      }`}
    >
      {[{ value: "", label: "Please select the country" }, ...options].map(
        (option) => (
          <option
            value={option.value}
            key={option.value + "-country-select"}
            className="!text-[rgb(0,0,0)] dark:!text-white"
          >
            {option.label}
          </option>
        )
      )}
    </select>
  );
};

import axios from "axios";

import { getCookie } from "../utils/cookie";

const profileInstance = axios.create();

// // Add a request interceptor
profileInstance.interceptors.request.use((config) => {
  // Modify the request config to include XSRF header and cookie
  config.headers["X-CSRFTOKEN"] = getCookie("csrftoken");
  config.xsrfCookieName = "csrftoken";
  config.withCredentials = true; // Ensure the cookie is sent with the request
  return config;
});

export interface User {
  email: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
}

export interface Profile {
  is_allowed: boolean;
  nickname: string;
  user: User;
  calendars: string[];
}

export interface ProfileEmail {
  id: number;
  email: string;
}

export interface Calendar {
  id: number;
  email: string;
  nation: string;
}

const service = {
  getProfile: () =>
    profileInstance
      .get<Profile>("/api/profile")
      .then((response) => response.data),
  getEmails: () =>
    profileInstance
      .get<Calendar[]>("/api/calendars")
      .then((response) => response.data),
  setCalendars: (calendars: Calendar[]) =>
    profileInstance.put(
      "/api/calendars/bulk",
      calendars.map((calendar) => {
        return { email: calendar.email, nation: calendar.nation };
      })
    ),
  setProfile: (nickName: string | undefined, calendars: string[]) =>
    profileInstance
      .put<Profile>("/api/profile", {
        nickname: nickName,
        calendars,
      })
      .then((response) => response.data),
};

export default service;

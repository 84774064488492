import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import axios from "axios";
import Cookies from 'js-cookie';
import App from "./App";

axios.interceptors.request.use((config) => {
  if (config.method?.toLowerCase() !== 'get') {
    config.headers['X-CSRFToken'] = Cookies.get('csrftoken') || '';
  }
  return config;
})

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>
);

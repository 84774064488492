import EmailManagementCard from "./components/EmailManagementCard";
import NickNameCard from "./components/NickNameCard";

const ProfilePage = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <NickNameCard />
        <EmailManagementCard />
      </div>
    </div>
  );
};

export default ProfilePage;

import { AiOutlineStop } from "react-icons/ai";

export default function Wait() {
  return (
    <div className="flex h-[100vh] w-full items-center justify-center dark:!bg-gray-800">
      {/* Sign in section */}
      <div className="mt-[10vh] flex w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          <AiOutlineStop />
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-800 dark:text-gray-400">
          Your email is not allowed. Please contact administrator.
        </p>
      </div>
    </div>
  );
}

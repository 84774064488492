import React, { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { TbSortDescending, TbSortAscending } from "react-icons/tb";
import { Hourglass } from "react-loader-spinner";

import statisticService from "../../../services/statistic";
import Card from "components/card";
import { useApplicationContext } from "App";

const tableHeads: {
  value:
    | "calendar_owner"
    | "monday_count"
    | "tuesday_count"
    | "wednesday_count"
    | "thursday_count"
    | "friday_count"
    | "saturday_count"
    | "sunday_count"
    | "total_count"
    | "total_interview_hours"
    | "tech_count"
    | "final_count";
  label: string;
}[] = [
  { value: "calendar_owner", label: "Nickname" },
  { value: "monday_count", label: "Mon" },
  { value: "tuesday_count", label: "Tue" },
  { value: "wednesday_count", label: "Wed" },
  { value: "thursday_count", label: "Thu" },
  { value: "friday_count", label: "Fri" },
  { value: "saturday_count", label: "Sat" },
  { value: "sunday_count", label: "Sun" },
  { value: "total_count", label: "Total" },
  { value: "total_interview_hours", label: "Hours" },
];

type RowObj = {
  calendar_owner: string;
  owner_type: string;
  sunday_count: number;
  monday_count: number;
  tuesday_count: number;
  wednesday_count: number;
  thursday_count: number;
  friday_count: number;
  saturday_count: number;
  tech_count: number;
  final_count: number;
  total_count: number;
  total_interview_hours: number;
};

function DashBoardTable({ setTopPerformers }: { setTopPerformers: any }) {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "total_count", desc: true },
  ]);
  const { selectedDateForReport } = useApplicationContext();
  const weekDate = useMemo(
    () => selectedDateForReport,
    [selectedDateForReport.startDate]
  );

  const columns = tableHeads.map((head) =>
    columnHelper.accessor(head.value, {
      id: head.value,
      header: () => (
        <p
          className="whitespace-nowrap text-sm font-bold uppercase text-gray-600 dark:text-white"
          style={{ fontSize: "12px" }}
        >
          {head.label}
        </p>
      ),
      cell: (info) => {
        return (
          <p className="text-sm text-navy-700 dark:text-white">
            {info.column.id === "total_count"
              ? `${info.getValue()} (${
                  info.row.original.total_count -
                  info.row.original.tech_count -
                  info.row.original.final_count
                } / ${info.row.original.tech_count} / ${
                  info.row.original.final_count
                })`
              : info.getValue()}
          </p>
        );
      },
    })
  );

  const { data, isLoading } = useQuery({
    queryKey: ["weeklyReports", weekDate.startDate.toISOString()],
    queryFn: () => statisticService.getWeekly(weekDate?.startDate as Date),
    refetchInterval: 300000,
  });

  const eventData = useMemo(() => data ?? [], [data]);

  const dataColNames: (keyof RowObj)[] = [
    "monday_count",
    "tuesday_count",
    "wednesday_count",
    "thursday_count",
    "friday_count",
    "saturday_count",
    "sunday_count",
    "total_count",
    "total_interview_hours",
    "final_count",
    "tech_count",
  ];

  let totalRow = eventData.reduce(
    (accumulator: any, currentValue) => {
      dataColNames.forEach(
        (column) => (accumulator[column] += currentValue[column])
      );
      return accumulator;
    },
    {
      calendar_owner: "Total",
      owner_type: "nickname",
      sunday_count: 0,
      monday_count: 0,
      tuesday_count: 0,
      wednesday_count: 0,
      thursday_count: 0,
      friday_count: 0,
      saturday_count: 0,
      total_count: 0,
      tech_count: 0,
      final_count: 0,
      total_interview_hours: 0,
    }
  );

  const performers = useMemo(() => {
    const filteredOnes = eventData.filter(
      (item) => item.tech_count + item.final_count > 0
    );
    filteredOnes.sort(
      (a, b) => b.tech_count + b.final_count - (a.tech_count + a.final_count)
    );
    return filteredOnes.slice(0, 5);
  }, [eventData]);

  const table = useReactTable({
    data: eventData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setTopPerformers(performers);
  }, [performers]);

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 dark:!bg-slate-800"}>
      <header className="relative flex items-center justify-between py-4">
        <div className="pt-1 text-xl font-bold text-navy-700 dark:text-white">
          Weekly Reports
        </div>
      </header>

      {
        <div className="overflow-x-auto xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-600 border-opacity-10 pb-2 pr-4 pt-3 text-start"
                      >
                        <div className="flex items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <div>
                            {(header.column.getIsSorted() as string) ===
                            "desc" ? (
                              <TbSortDescending className="text-gray-800 dark:text-white" />
                            ) : (header.column.getIsSorted() as string) ===
                              "asc" ? (
                              <TbSortAscending className="text-gray-800 dark:text-white" />
                            ) : (
                              <TbSortAscending className="opacity-0" />
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {eventData.length > 0 &&
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.original.calendar_owner}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="border-white/0 py-3  pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              <tr key="total_row">
                {["calendar_owner", ...dataColNames].map(
                  (col) =>
                    col !== "tech_count" &&
                    col !== "final_count" && (
                      <td
                        key={`total_${col}_data`}
                        className="border-white/0 py-3  pr-4"
                      >
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {col === "total_interview_hours"
                            ? totalRow[col].toFixed(2)
                            : col === "total_count"
                            ? `${totalRow[col]} (${
                                totalRow[col] -
                                totalRow["tech_count"] -
                                totalRow["final_count"]
                              } / ${totalRow["tech_count"]} / ${
                                totalRow["final_count"]
                              })`
                            : totalRow[col]}
                        </p>
                      </td>
                    )
                )}
              </tr>
            </tbody>
          </table>
          {(eventData.length === 0 || isLoading) && (
            <div className="mb-5 flex w-full justify-center pt-2">
              {isLoading ? (
                <div className="m-auto flex flex-row items-center justify-center gap-3">
                  <Hourglass
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="hourglass-loading"
                    colors={["#306cce", "#72a1ed"]}
                  />{" "}
                  Loading...
                </div>
              ) : (
                <>No data found.</>
              )}
            </div>
          )}
        </div>
      }
    </Card>
  );
}

export default DashBoardTable;
const columnHelper = createColumnHelper<RowObj>();

import { Suspense } from "react";
import PieChartContainer from "./components/Chart";
import StatisticsTable from "./components/StatisticsTable";

const StatisticsPage = () => {
  return (
    <div>
      <div className="mt-5 flex h-full grid-cols-1 flex-row gap-5">
        <div className="w-full">
          <StatisticsTable />
        </div>
      </div>
    </div>
  );
};

export default StatisticsPage;
